import useKeys from '@flowsn4ke/usekeys';
import DragScrollContainer from 'components/ui/DragScrollContainer';

export default function FilterBar({ filters = [], sortingOrders = [] }) {
  const k = useKeys();

  if (!filters.length) return null;

  return (
    <DragScrollContainer
      className="relative py-1"
      sortingOrders={sortingOrders}
    >
      {filters.map((filter, filterIdx) => (
        <div key={k(filterIdx)}>{filter}</div>
      ))}
    </DragScrollContainer>
  );
}
