import FAIcon from 'components/ui/FAIcon';
import Menu from 'components/menu/Menu';
import IconButton from 'components/IconButton';
import MenuItem from 'components/menu/MenuItem';

export default function FilterSetsManager() {
  return (
    <>
      <Menu
        button={() => (
          <IconButton className="m-1 rounded-full p-1">
            <FAIcon
              collection="far"
              icon="sliders-up"
              size="small"
            />
          </IconButton>
        )}
        items={[
          <MenuItem
            icon="cog"
            label="settings"
          />
        ]}
      />
      <IconButton
        button
        className="m-1 rounded-full p-1"
      >
        <FAIcon
          collection="far"
          icon="floppy-disk"
          size="small"
        />
      </IconButton>
    </>
  );
}
