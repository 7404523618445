import { pick, isObject } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { COMPANY_CLIENT, COMPANY_CONTRACTOR } from 'constants/roles';
import {
  CONTRACT_REMOVED,
  CONTRACT_WAITINGPLAN,
  CONTRACT_VALIDATED,
  CONTRACT_DROPPED,
  CONTRACT_DECLINED,
  CONTRACT_TOREPLAN,
  CONTRACT_VISIT_DEVIS,
  CONTRACT_INTERVENTION_FINISH
} from 'lists/contractStates';

import moment from 'moment';
import { addBudgetOnTicket, updateBudgets, updateBudgetsUse } from './budgetsSlice';
import { assign } from '@recursive/assign';

export const publicCreateElement = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/public' });
export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'tickets' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'tickets/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/list' });
export const getBookmarks = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/bookmarks' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'tickets/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/recycle/${id}'
});

export const duplicateElement = generateAsyncThunk({
  type: 'GET',
  endpoint: 'tickets/duplicate/${id}'
});
export const cancelElement = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/cancel' });
export const acceptDevis = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/devis/response'
});
export const acceptElement = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/ticket/state'
});
export const finishElement = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/finish' });
export const updateManagers = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/updateManagers'
});
export const closeElement = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/close' });
export const openElement = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/open' });
export const relanceIntervener = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/relance/${id}'
});
export const renoticeIntervener = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/renotice'
});
export const assignIntervener = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/contractor/assign'
});
export const proposeIntervener = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/contractor/propose'
});
export const cancelIntervener = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/contractor/cancel'
});
export const rateIntervener = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/rate' });

export const addAccounting = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'tickets/${id}/attachments'
});
export const addQuote = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'ticket_documents/${id}/upload-quote'
});
export const addInvoice = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'ticket_documents/${id}/upload-invoice'
});
export const removeQuote = generateAsyncThunk({
  type: 'POST',
  endpoint: 'ticket_documents/${id}/remove-quote'
});
export const removeInvoice = generateAsyncThunk({
  type: 'POST',
  endpoint: 'ticket_documents/${id}/remove-invoice'
});
export const updateAccounting = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/attachments/update'
});
export const removeAccounting = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/removeAttachment'
});

export const updatePrice = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/${id}/price' });

export const linkElements = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/${id}/link' });

export const toReplan = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/${id}/replan' });
export const setDate = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/date' });
export const updateDate = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets/${id}/date/prop' });

export const createDeadline = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/deadline'
});
export const updateDeadline = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/deadline'
});
export const deleteDeadline = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'tickets/${id}/deadline'
});

export const createNote = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/${id}/notes' });
export const updateNote = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/notes${noteIdWithSlash}'
});
export const deleteNote = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'tickets/${id}/notes${noteIdWithSlash}'
});

export const createInterventionReport = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'miscs/interventionReport'
});
export const readInterventionReports = generateAsyncThunk({
  type: 'GET',
  endpoint: 'tickets/${id}/reports'
});
export const updateInterventionReport = generateAsyncThunk({
  type: 'FILE-PUT',
  endpoint: 'miscs/interventionReport/${id}'
});
export const deleteInterventionReport = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'miscs/interventionReport/${id}'
});
export const uploadReport = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'tickets/${id}/interventionReport'
});
// TODO change when backend is ready and merge for this one
export const newUploadReport = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'ticket_documents/${id}/upload-report'
});
export const liftTheReserve = generateAsyncThunk({
  type: 'GET',
  endpoint: 'tickets/${id}/interventionReport/reserve'
});

export const consumeStock = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/${id}/consume' });

// * DELAYS
export const putDelays = generateAsyncThunk({ type: 'PUT', endpoint: 'tickets2/${id}/delays' });
export const deleteDelay = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'tickets2/${id}/delays'
});

export const computeState = (contract, element, parent) => {
  return !!element.deleted_at
    ? CONTRACT_REMOVED
    : element.flag_declined
      ? CONTRACT_DECLINED
      : element.notice_waitingplan
        ? CONTRACT_WAITINGPLAN
        : element.notice_replan
          ? CONTRACT_TOREPLAN
          : element.notice_dropped
            ? CONTRACT_DROPPED
            : (parent || element).notice_validated
              ? CONTRACT_VALIDATED
              : contract.state === 'intervention' &&
                moment().isSameOrAfter(parent?.intervention_date || contract.intervention_date)
                ? CONTRACT_INTERVENTION_FINISH
                : contract.state === 'visit' && moment().isSameOrAfter(parent?.visit_date || contract.visit_date)
                  ? CONTRACT_VISIT_DEVIS
                  : contract.state;
};

const pickContract = (contract = {}, element = {}, parent) => {
  return {
    ...pick(contract, [
      '_client',
      '_id',
      'contractId',
      'visit_date',
      'history',
      'intervention_date',
      'intervention_compute',
      'seuil_enable',
      'seuil',
      '_document',
      'summonsIsCollaborator',
      'summonsIsContractor',
      'reason_canceled',
      'replan_reason',
      'relances',
      'facture_state',
      'deadline',
      'intervention_price',
      '_consumptions',
      '_tva',
      '_summons',
      'devis',
      'factures',
      'visit_dates',
      'intervention_dates',
      '_propositions'
    ]),
    _managers: (contract._managers || []).filter((m) => !!m),
    _facture_ticket: element._facture_ticket ? pick(element._facture_ticket, ['factures', 'number', '_id']) : null,
    state: computeState(contract, element, parent)
  };
};

export const formatElement = (element) => {
  const contract = pickContract(element, element, element._parent);
  const contractParent = element._parent
    ? {
      ...pickContract(element._parent, element),
      _company: {
        ...pick(element._parent._owner, ['_id', 'avatar', 'regime', 'firstName', 'lastName', 'userInterface']),
        name:
          element._parent.ownerType === 'Client' ? element._parent._owner?.name : element._parent._owner?.companyName,
        type: element._parent.ownerType === 'Client' ? COMPANY_CLIENT : COMPANY_CONTRACTOR
      }
    }
    : false;

  const contractContractor =
    !!contract._summons?.length && contract.summonsIsContractor
      ? {
        ...pickContract(isObject(contract._summons[0]) ? contract._summons[0].view : {}, element),
        _company: !isObject(contract._summons[0])
          ? {}
          : {
            ...pick(contract._summons[0], ['_id', 'avatar']),
            name: contract._summons[0].companyName,
            type: COMPANY_CONTRACTOR
          }
      }
      : false;

  const contractTechnician = !contractContractor && !!contract._summons?.length;

  const companyCreator = element._contractor || element._client;
  const contractCreator =
    contractParent && contractParent?._company?._id !== companyCreator._id
      ? {
        _company: {
          ...pick(companyCreator, ['_id', 'avatar']),
          name: companyCreator.companyName || companyCreator.name,
          type: element._contractor ? COMPANY_CONTRACTOR : COMPANY_CLIENT
        }
      }
      : false;

  const period_ticket =
    isObject(element._period) && element._period.periods.find((period) => period._ticket === element._id);
  const period = isObject(element._period)
    ? {
      _preventif: element._preventif,
      config: element._period.config,
      periods: element._period.periods,
      ...(period_ticket || { ticketPeriodNotFound: true }),
      index: element._period.periods.findIndex((period) => period._ticket === element._id) + 1,
      checked: element.preventif_checked,
      hasIR: element.hasIR,
      hasReserve: element.hasReserveUrgency,
      _id: element._period._id
    }
    : false;

  const result = {
    ...pick(element, [
      '_id',
      'fields',
      'tabs',
      'documents_validation',
      'created_at',
      'deleted_at',
      'deleted_by',
      'flag_canceled',
      'flag_declined',
      '_user',
      'title',
      'description',
      '_typology',
      '_locations',
      '_equipments',
      '_contacts',
      '_jobs',
      'number',
      'relances',
      'notes',
      'viewNotes',
      'viewNotesManagers',
      'documents_tab_id',
      'urgency',
      'autoassign',
      'guarantees',
      'contracts',
      '_document',
      '_ticket_parents',
      '_tickets_linked',
      'category',
      'domaine',
      'creator',
      'views',
      'creator_email',
      'creator_phone',
      '_ticket_reserve',
      'budgets',
      'intervention_delay',
      'intervention_deadline',
      'resolution_delay',
      'resolution_deadline',
      'intervention_after_deadline',
      'resolution_after_deadline'
    ]),
    contractParent,
    contract,
    contractTechnician,
    contractCreator,
    companyCreator,
    contractContractor,
    creatorConfiguration: companyCreator._configuration,
    period
  };

  return result;
};

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement,
  formatElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: { ...initialState },
  reducers: {
    ...reducers,
    updateDocuments: (state, action) => {
      const { _id, sub } = action.payload;
      if (state.db[_id]) {
        state.db[_id].tabs.documents = sub ? state.db[_id].tabs.documents - 1 : state.db[_id].tabs.documents + 1;
      }
    },
    ticketPeriodUpdateElement: (state, action) => {
      const { value, ticket } = action.payload;

      if (state.db[ticket]) {
        state.db[ticket].period.start_time = value.start_time;
        state.db[ticket].period.end_time = value.end_time;
      }
    }
  },
  // TODO: Stop populating the ticket / template in the http response (the id is enough)
  extraReducers: {
    ...extraReducers,
    [updateElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [uploadReport.fulfilled]: (state, action) => {
      if (state.db[action.meta.id]) {
        const next = state.db[action.meta.id].reports || [];
        next.push(formatReport(action.payload.data));

        state.db[action.meta.id].reports = next;
        if (state.db[action.meta.id].tabs.reports) {
          state.db[action.meta.id].tabs.reports = state.db[action.meta.id].tabs.reports + 1;
        } else {
          state.db[action.meta.id].tabs.reports = 1;
        }

        if (action.payload.data.reserve) {
          state.db[action.meta.id] = assign(state.db[action.meta.id], {
            period: {
              hasReserve: action.payload.data.urgency
            }
          });
        }
      }
    },
    [newUploadReport.fulfilled]: (state, action) => {
      if (state.db[action.meta.id]) {
        const next = state.db[action.meta.id].reports || [];
        next.push(formatReport(action.payload.data));

        state.db[action.meta.id].reports = next;
        if (state.db[action.meta.id].tabs.reports) {
          state.db[action.meta.id].tabs.reports = state.db[action.meta.id].tabs.reports + 1;
        } else {
          state.db[action.meta.id].tabs.reports = 1;
        }

        if (action.payload.data.reserve) {
          state.db[action.meta.id] = assign(state.db[action.meta.id], {
            period: {
              hasReserve: action.payload.data.urgency
            }
          });
        }
      }
    },
    [liftTheReserve.fulfilled]: (state, action) => {
      const reports = state.db[action.meta._ticket].reports;
      state.db[action.meta._ticket].reports = reports.map((r) => {
        return r._id !== action.meta.id ? r : { ...r, reserve: false, reserveDate: new Date() };
      });

      if (state.db[action.meta._ticket].period) {
        const has_some_reports_with_reserve = reports
          .filter((r) => String(r._id) !== String(action.meta.id))
          .some((r) => r.reserve);

        const report_with_higher_level_urgency = state.db[action.meta._ticket].reports
          .filter((r) => r.reserve)
          .sort((a, b) => a.urgency - b.urgency) // sort by urgency asc (1, 2, 3)
          .pop()?.urgency;

        state.db[action.meta._ticket] = assign(state.db[action.meta._ticket], {
          period: {
            hasReserve: has_some_reports_with_reserve ? report_with_higher_level_urgency : 0
          }
        });
      }
    },
    [createInterventionReport.fulfilled]: (state, action) => {
      const ticketId = JSON.parse(action.meta.arg.formData.get('interventionReport'))._ticket;

      if (state.db[ticketId]) {
        const next = state.db[ticketId].reports || [];
        next.push(formatReport(action.payload.data));
        state.db[ticketId].reports = next;

        if (state.db[ticketId]?.tabs?.reports) {
          state.db[ticketId].tabs.reports += 1;
        } else {
          state.db[ticketId].tabs = {
            ...state.db[ticketId].tabs,
            reports: 1
          };
        }
      }
    },
    [readInterventionReports.fulfilled]: (state, action) => {
      state.db[action.meta.id].reports = action.payload.data.reports;
      state.db[action.meta.id].tabs = {
        ...state.db[action.meta.id]?.tabs,
        reports: action.payload.data.reports?.length
      };
    },
    [updateInterventionReport.fulfilled]: (state, action) => {
      const ticketId = action.meta.arg.params.ticketId;
      const reports = state.db[ticketId].reports;
      state.db[ticketId].reports = reports.map((r) => {
        return r._id !== action.meta.id ? r : formatReport(action.payload.data, r);
      });
    },
    [deleteInterventionReport.fulfilled]: (state, action) => {
      const ticketId = action.meta.arg.params.ticketId;
      const reports = state.db[ticketId].reports;
      const reportToDelete = reports.find((r) => r._id === action.meta.arg.params.id);
      state.db[ticketId].reports = reports.filter((r) => r._id !== action.meta.arg.params.id);

      if (state.db[ticketId].period && reportToDelete.reserve) {
        state.db[ticketId].period.hasReserve = false;
      }

      if (state.db[ticketId].tabs?.reports) {
        state.db[ticketId].tabs.reports -= 1;
      }
    },
    [duplicateElement.fulfilled]: (state, action) => {
      const { listId } = action.meta;
      const { _id } = action.payload.data.element;
      const { element } = action.payload.data;

      state.db[_id] = formatElement(element);

      state.lists[listId] = [{ _id }, ...state.lists[listId]];
      state.lists[listId].count += 1;
    },
    [cancelElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [acceptElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [finishElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [closeElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [openElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [acceptDevis.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [updateManagers.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [renoticeIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [relanceIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [cancelIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [assignIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [proposeIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [rateIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [setDate.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [updateDate.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [addAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [updateAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [removeAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [linkElements.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [toReplan.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [updatePrice.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [createDeadline.fulfilled]: (state, action) => {
      const next = pick(action.meta.arg, ['date', 'type', 'description', 'email']);
      state.db[action.meta.id].contract.deadline = next;
    },
    [updateDeadline.fulfilled]: (state, action) => {
      const next = pick(action.meta.arg, ['date', 'type', 'description', 'email']);
      state.db[action.meta.id].contract.deadline = next;
    },
    [deleteDeadline.fulfilled]: (state, action) => {
      state.db[action.meta.id].contract.deadline = null;
    },
    [createNote.fulfilled]: (state, action) => {
      // TODO: Stop sending back the whole ticket every time we touch a note
      // TODO: Send back only the note type we actually need, or the note we created with the id
      const type = action.meta.arg.view;
      type === 'ticket'
        ? (state.db[action.meta.id].notes = action.payload.data.ticket.notes)
        : type === 'view'
          ? (state.db[action.meta.id].viewNotes = action.payload.data.ticket.viewNotes)
          : (state.db[action.meta.id].viewNotesManagers = action.payload.data.ticket.viewNotesManagers);
    },
    [updateNote.fulfilled]: (state, action) => {
      state.db[action.meta.id].notes = action.payload.data.ticket.notes;
      state.db[action.meta.id].viewNotes = action.payload.data.ticket.viewNotes;
      state.db[action.meta.id].viewNotesManagers = action.payload.data.ticket.viewNotesManagers;
    },
    [deleteNote.fulfilled]: (state, action) => {
      const filter = (arr) => arr.filter((n) => n._id !== action.meta.noteIdWithSlash.slice(1));

      state.db[action.meta.id].notes = filter(state.db[action.meta.id].notes);
      state.db[action.meta.id].viewNotes = filter(state.db[action.meta.id].viewNotes);
      state.db[action.meta.id].viewNotesManagers = filter(state.db[action.meta.id].viewNotesManagers);
    },
    [updateBudgetsUse.fulfilled]: (state, action) => {
      const { ticket_id, budgets } = action.meta.arg;

      if (action.payload.data === 'OK') {
        state.db[ticket_id].budgets = budgets;
      }
    },
    [addBudgetOnTicket.fulfilled]: (state, action) => {
      const { ticket_id } = action.meta.arg;

      state.db[ticket_id].budgets = assign(state.db[ticket_id].budgets, action.payload.data.budgets);
    },
    [updateBudgets.fulfilled]: (state, action) => {
      const { budgets } = action.meta.arg;

      if (action.payload.status === 200) {
        const update_infos_budgets = Object.keys(state.db).map((ticket_id) => {
          return state.db[ticket_id].budgets.map((budget) => {
            const update_budget = budgets.find(({ _id }) => String(_id) === budget.budget_id);

            if (update_budget) {
              return {
                ...budget,
                title: update_budget.title,
                year: update_budget.year
              };
            }
            return budget;
          });
        });

        Object.keys(state.db).forEach((ticket_id, i) => {
          state.db[ticket_id].budgets = update_infos_budgets[i];
        });
      }
    },
    [addQuote.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [addInvoice.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [removeQuote.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [removeInvoice.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [putDelays.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    },
    [deleteDelay.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = formatElement(element);
    }
  }
});

export const { flushElements, flushElement, updateDocuments, ticketPeriodUpdateElement } = ticketsSlice.actions;
export default ticketsSlice.reducer;

function formatReport(payload, report) {
  return {
    ...report,
    ...payload,
    _ticket: payload._ticket._id,
    ...(payload?._template
      ? {
        _template: {
          ...payload._template,
          ...(payload._template._contractor ? { _contractor: payload._template._contractor._id } : {}),
          ...(payload._template._client ? { _client: payload._template._client._id } : {})
        }
      }
      : {})
  };
}
