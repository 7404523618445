import { useRef, useState, useLayoutEffect } from 'react';
import FAIcon from 'components/ui/FAIcon';
import ScrollContainer from 'react-indiana-drag-scroll';
import classNames from 'classnames';
import Menu from 'components/menu/Menu';
import IconButton from 'components/IconButton';
import { useField } from 'frmx';
import MenuItem from 'components/menu/MenuItem';
import useKeys from '@flowsn4ke/usekeys';

export default function DragScrollContainer({ children, className, sortingOrders }) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const scrollContainerNode = containerRef.current?.container?.current;
    const isScrollable = scrollContainerNode?.scrollWidth > scrollContainerNode?.clientWidth;
    setIsEnd(!isScrollable);
  }, []);

  return (
    <div className={classNames('isolate flex', className)}>
      {sortingOrders.length > 0 && <SortingMenu sortingOrders={sortingOrders} />}
      <div className="w-full">
        {!isBeginning && (
          <button
            className="w-8 h-full cursor-pointer absolute top-0 left-0 z-20 backdrop-blur-sm"
            onClick={() => {
              const scrollContainerNode = containerRef.current?.container?.current;
              scrollContainerNode?.scrollBy({ left: -250 });
            }}
          >
            <FAIcon
              collection="far"
              icon="angles-left"
              size="small"
            />
          </button>
        )}
        <ScrollContainer
          className="scroll-container scroll-smooth flex space-x-2 p-1 cursor-grab active:cursor-grabbing"
          ref={containerRef}
          onEndScroll={() => {
            const scrollContainerNode = containerRef.current?.container?.current;
            const isAtBeginning = scrollContainerNode?.scrollLeft === 0;
            const isAtEnd =
              Math.round(scrollContainerNode?.scrollLeft + scrollContainerNode?.clientWidth) >=
              scrollContainerNode?.scrollWidth;

            setIsBeginning(isAtBeginning);
            setIsEnd(isAtEnd);
          }}
        >
          {children}
          <div className="mr-1">&nbsp;</div>
        </ScrollContainer>
        {!isEnd && (
          <button
            className="w-8 h-full cursor-pointer absolute top-0 right-0 z-10 backdrop-blur-sm"
            onClick={(event) => {
              const scrollContainerNode = containerRef.current?.container?.current;
              scrollContainerNode?.scrollBy({ left: 250 });
            }}
          >
            <FAIcon
              collection="far"
              icon="angles-right"
              size="small"
            />
          </button>
        )}
      </div>
    </div>
  );
}

function SortingMenu({ sortingOrders }) {
  const k = useKeys();

  const { value: order, setValue: setOrder } = useField('order');

  return (
    <Menu
      button={() => (
        <IconButton className="m-1 rounded-full p-1">
          <FAIcon
            collection="fas"
            icon="bars-sort"
            size="small"
          />
        </IconButton>
      )}
      items={sortingOrders.map((sortingOrder, sortingOrderIdx) => (
        <MenuItem
          key={k(sortingOrderIdx)}
          icon={sortingOrder.icon}
          label={sortingOrder.label}
          onClick={() => setOrder(sortingOrder.sortKey)}
          className={order === sortingOrder.sortKey ? 'bg-gray-200' : ''}
        />
      ))}
    />
  );
}
