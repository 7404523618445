import { useForm, useResetListener } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import useDebouncedState from 'hooks/useDebouncedState';
import { useEffect } from 'react';
import Menu from 'components/menu/Menu';
import IconButton from 'components/IconButton';
import FilterSetsManager from 'components/filters/FilterSetsManager';

export default function SearchBar({
  totalResults,
  filterSetsManager,
  path,
  menuItems,
  downloadLoading,
  resizeable,
  setResize
}) {
  const { resetForm, getOneField, setOneField } = useForm();

  const [search, setSearch, debouncedSearch] = useDebouncedState(getOneField(path), 700);

  useEffect(() => {
    getOneField(path) !== debouncedSearch && setOneField(path, debouncedSearch);
  }, [debouncedSearch]);
  useResetListener(() => setSearch(''));

  return (
    <div className="flex items-center h-10 w-full my-auto">
      <Menu
        button={() => (
          <IconButton className="m-1 rounded-full p-1">
            <FAIcon
              collection="fas"
              icon={downloadLoading ? 'spinner-third' : 'ellipsis-vertical'}
              size="small"
              className={downloadLoading && 'animate-spin'}
            />
          </IconButton>
        )}
        items={menuItems}
      />
      <FAIcon
        className="mx-1"
        collection="far"
        icon="magnifying-glass"
        size="small"
      />
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="block w-full sm:text-sm md:text-md p-1"
        placeholder={`Rechercher ${
          typeof totalResults === 'number' ? `parmi les ${totalResults} résultats` : ''
        }`}
        focus="true"
      />
      <IconButton
        button
        onClick={() => resetForm()}
      >
        <FAIcon
          collection="far"
          icon="circle-xmark"
          size="md"
        />
      </IconButton>

      {resizeable && (
        <IconButton
          button
          onClick={() => setResize((prev) => !prev)}
        >
          <FAIcon
            collection="far"
            icon="chevron-left"
            size="sm"
          />
        </IconButton>
      )}
      {filterSetsManager && <FilterSetsManager />}
    </div>
  );
}
