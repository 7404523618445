import { useField } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';

function getColor(color) {
  switch (color) {
    case 'red':
      return {
        activeIconColor: 'text-red-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-red-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-red-500'
      };
    case 'green':
      return {
        activeIconColor: 'text-green-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-green-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-green-500'
      };
    case 'sky':
      return {
        activeIconColor: 'text-sky-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-sky-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-sky-500'
      };
    case 'blue':
      return {
        activeIconColor: 'text-blue-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-blue-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-blue-500'
      };
    case 'yellow':
      return {
        activeIconColor: 'text-yellow-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-yellow-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-yellow-500'
      };
    case 'orange':
      return {
        activeIconColor: 'text-orange-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-orange-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-orange-500'
      };
    case 'purple':
      return {
        activeIconColor: 'text-purple-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-purple-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-purple-500'
      };
    case 'pink':
      return {
        activeIconColor: 'text-pink-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-pink-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-pink-500'
      };
    case 'teal':
      return {
        activeIconColor: 'text-teal-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-teal-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-teal-500'
      };
    case 'slate':
      return {
        activeIconColor: 'text-slate-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-slate-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-slate-500'
      };
    default:
      return {
        activeIconColor: 'text-green-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-green-600',
        inactiveBorderColor: 'border-green-300',
        activeBorderColor: 'border-green-500'
      };
  }
}

export default function SectionPicker({ path, label, color = 'green' }) {
  const { t } = useTranslation();
  const { value: isActive, setValue: setIsActive } = useField(path);
  const {
    activeIconColor,
    inactiveIconColor,
    inactiveTextColor,
    activeTextColor,
    inactiveBorderColor,
    activeBorderColor
  } = getColor(color);

  return (
    <button
      onClick={() => setIsActive((prev) => !prev)}
      className={`cursor-pointer border ${isActive ? activeBorderColor : inactiveBorderColor} inline-flex text-gray-500 items-center pl-2 pr-3 h-8 rounded-full text-xs font-medium ${isActive ? activeTextColor : inactiveTextColor}`}
    >
      <FAIcon
        collection={isActive ? 'fas' : 'fal'}
        icon={isActive ? 'circle-check' : 'circle'}
        size="small"
        className={`mr-1 ${isActive ? activeIconColor : inactiveIconColor}`}
      />
      <span className="whitespace-nowrap">{t(label)}</span>
    </button>
  );
}
