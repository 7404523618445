import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLink,
  faHouse,
  faCrown,
  faBuilding,
  faCoffee,
  faCogs,
  faCog,
  faPowerOff,
  faHeart,
  faIdBadge,
  faBan,
  faFingerprint,
  faMailbox,
  faSirenOn,
  faExclamationTriangle,
  faFiles,
  faPalette,
  faHouseBuilding,
  faBoxTaped,
  faChartLine,
  faImagePolaroid,
  faCircle,
  faTrash,
  faPen,
  faRocket,
  faBoxBallot,
  faMapMarkerAlt,
  faCode,
  faUserHardHat,
  faUser,
  faSpinnerThird,
  faChessClockAlt,
  faEuroSign,
  faDollarSign,
  faYenSign,
  faSterlingSign,
  faFrancSign,
  faChfSign,
  faCalculator,
  faLifeRing,
  faToolbox,
  faSitemap,
  faBolt,
  faKey,
  faSignal,
  faFileInvoice,
  faAsterisk,
  faInfoCircle,
  faShieldCheck,
  faBellOn,
  faHardHat,
  faShapes,
  faFileImage,
  faSortAlphaDown,
  faCloudUpload,
  faBookSpells,
  faSpinner,
  faIdCard,
  faSlidersV,
  faQuoteLeft,
  faPortalEnter,
  faPortalExit,
  faCloudDownload,
  faFileChartPie,
  faSave,
  faFileContract,
  faPencil,
  faPaperclip,
  faEnvelopeOpen,
  faEnvelope,
  faPhone,
  faMobile,
  faGlobe,
  faLayerMinus,
  faFileSearch,
  faBarcode,
  faUserCheck,
  faEngineWarning,
  faCalendarDay,
  faCarrot,
  faCalendar,
  faMapMarker,
  faUserTie,
  faChartPie,
  faPhoneRotary,
  faHeartbeat,
  faWrenchSimple,
  faUserHeadset,
  faAt,
  faPlusCircle,
  faExclamationCircle,
  faAdd,
  faCalendarWeek,
  faLayerGroup,
  faAddressCard,
  faHeading,
  faAlicorn,
  faClub,
  faAlienMonster,
  faHeartCircle,
  faFileAlt,
  faLineHeight,
  faParagraph,
  faSortNumericDown,
  faCheckSquare,
  faList,
  faExclamationSquare,
  faStar,
  faNewspaper,
  faText,
  faTypewriter,
  faListOl,
  faInfinity,
  faInventory,
  faFilePlus,
  faThumbsUp,
  faBells,
  faThumbsDown,
  faThunderstorm,
  faMoneyCheckEdit,
  faWindowClose,
  faCalendarEdit,
  faCalendarPlus,
  faTrashAlt,
  faClipboardCheck,
  faArchive,
  faRecycle,
  faHeartRate,
  faDragon,
  faAlarmExclamation,
  faAlarmPlus,
  faCommentsAlt,
  faWrench,
  faCommentAlt,
  faPaperPlane,
  faClock,
  faFire,
  faFireFlame,
  faSkullCow,
  faSnake,
  faTasks,
  faTriangleExclamation,
  faWarehouse,
  faClipboardList,
  faShippingTimed,
  faGavel,
  faLock,
  faLockOpen,
  faComment,
  faSend,
  faCheck,
  faCircleMinus,
  faCheckCircle,
  faCircleCheck,
  faHistory,
  faUserUnlock,
  faBox,
  faStarSharp,
  faHandshake,
  faScrewdriverWrench,
  faFaceFrown,
  faMessageLines,
  faToggleOn,
  faLoader,
  faEye,
  faEllipsis,
  faEllipsisVertical,
  faRotateRight,
  faCaretDown,
  faFolderPlus,
  faCloudArrowUp,
  faRotate,
  faInfo,
  faPenToSquare,
  faTrashCan,
  faShare,
  faICursor,
  faTrashCanSlash,
  faTrashCanUndo,
  faRotateLeft,
  faArrowDownToBracket,
  faFloppyDisk,
  faBarsSort,
  faArrowUpZA,
  faArrowDownAZ,
  faSortUp,
  faSortDown,
  faDownload,
  faEquals,
  faNotEqual,
  faHourglassEnd,
  faMoneyBillTransfer,
  faWallet,
  faQuestion,
  faCalendarCirclePlus,
  faSparkles,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister,
  faCloudExclamation
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faSnake,
  faFolderPlus,
  faEllipsis,
  faTriangleExclamation,
  faCloudArrowUp,
  faTasks,
  faUserUnlock,
  faPortalEnter,
  faPortalExit,
  faFireFlame,
  faCommentsAlt,
  faCommentAlt,
  faBellOn,
  faFire,
  faFileChartPie,
  faSignal,
  faHouse,
  faBuilding,
  faDragon,
  faCoffee,
  faLink,
  faCogs,
  faCog,
  faPaperclip,
  faPowerOff,
  faHeart,
  faQuoteLeft,
  faFileContract,
  faSirenOn,
  faExclamationTriangle,
  faIdBadge,
  faTrashCanSlash,
  faFingerprint,
  faMailbox,
  faFiles,
  faPalette,
  faHouseBuilding,
  faBoxTaped,
  faChartLine,
  faImagePolaroid,
  faCircle,
  faPenToSquare,
  faTrash,
  faSparkles,
  faPen,
  faPencil,
  faBan,
  faRocket,
  faBoxBallot,
  faMapMarkerAlt,
  faUserHardHat,
  faUser,
  faSpinnerThird,
  faChessClockAlt,
  faCode,
  faEuroSign,
  faDollarSign,
  faYenSign,
  faSterlingSign,
  faFrancSign,
  faChfSign,
  faCalculator,
  faLifeRing,
  faToolbox,
  faEllipsisVertical,
  faSitemap,
  faBolt,
  faKey,
  faFileInvoice,
  faRotateLeft,
  faAsterisk,
  faCrown,
  faInfoCircle,
  faInfo,
  faShieldCheck,
  faHardHat,
  faShapes,
  faFileImage,
  faSortAlphaDown,
  faCloudUpload,
  faBookSpells,
  faSpinner,
  faIdCard,
  faSlidersV,
  faCloudDownload,
  faSave,
  faEnvelopeOpen,
  faEnvelope,
  faPhone,
  faMobile,
  faAlicorn,
  faClub,
  faAlienMonster,
  faHeartCircle,
  faGlobe,
  faLayerMinus,
  faFileSearch,
  faBarcode,
  faUserCheck,
  faEngineWarning,
  faCalendarDay,
  faCarrot,
  faCalendar,
  faMapMarker,
  faUserTie,
  faChartPie,
  faPhoneRotary,
  faHeartbeat,
  faWrenchSimple,
  faUserHeadset,
  faAt,
  faPlusCircle,
  faExclamationCircle,
  faAdd,
  faTrashCan,
  faICursor,
  faCalendarWeek,
  faLayerGroup,
  faAddressCard,
  faShare,
  faHeading,
  faFileAlt,
  faLineHeight,
  faParagraph,
  faSortNumericDown,
  faArrowDownToBracket,
  faCheckSquare,
  faList,
  faExclamationSquare,
  faTrashCanUndo,
  faStar,
  faNewspaper,
  faText,
  faTypewriter,
  faCaretDown,
  faListOl,
  faInfinity,
  faInventory,
  faFilePlus,
  faThumbsUp,
  faBells,
  faThumbsDown,
  faThunderstorm,
  faMoneyCheckEdit,
  faWindowClose,
  faCalendarEdit,
  faFloppyDisk,
  faCalendarPlus,
  faTrashAlt,
  faClipboardCheck,
  faArchive,
  faRecycle,
  faHeartRate,
  faAlarmExclamation,
  faAlarmPlus,
  faWrench,
  faPaperPlane,
  faClock,
  faTasks,
  faWarehouse,
  faClipboardList,
  faShippingTimed,
  faGavel,
  faLock,
  faLockOpen,
  faComment,
  faSend,
  faCheck,
  faCircleMinus,
  faCheckCircle,
  faCircleCheck,
  faHistory,
  faSkullCow,
  faBox,
  faStarSharp,
  faHandshake,
  faRotate,
  faRotateRight,
  faScrewdriverWrench,
  faFaceFrown,
  faMessageLines,
  faToggleOn,
  faLoader,
  faEye,
  faBarsSort,
  faArrowUpZA,
  faArrowDownAZ,
  faSortUp,
  faSortDown,
  faDownload,
  faEquals,
  faNotEqual,
  faHourglassEnd,
  faMoneyBillTransfer,
  faWallet,
  faQuestion,
  faCalendarCirclePlus,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister,
  faCloudExclamation
);
