import { Form } from 'frmx';
import FilterBar from 'components/filters/FilterBar';
import SearchBar from 'components/filters/SearchBar';
import BookmarkBar from './BookmarkBar';

export default function FilterAndSearchBarForm({
  search = true,
  filterSetsManager,
  totalResults,
  menuItems = [],
  searchPath = 'search',
  initialValues,
  onChange,
  onSubmit,
  filters = [],
  sortingOrders = [],
  withFilters,
  downloadLoading,
  className = '',
  bookmarks = [],
  resizeable = false,
  setResize = () => {}
}) {
  return (
    <Form
      className={className}
      initialValues={initialValues}
      {...(onChange ? { afterChange: onChange } : {})}
      {...(onSubmit ? { onSubmit: onSubmit } : {})}
    >
      {search && (
        <div>
          <SearchBar
            totalResults={totalResults}
            menuItems={menuItems}
            path={searchPath}
            filterSetsManager={filterSetsManager}
            downloadLoading={downloadLoading}
            resizeable={resizeable}
            setResize={setResize}
          />
        </div>
      )}
      {withFilters && (
        <div className="ml-2">
          <FilterBar
            filters={filters}
            sortingOrders={sortingOrders}
          />
        </div>
      )}
      <div className="ml-2">
        <BookmarkBar bookmarks={bookmarks} />
      </div>
    </Form>
  );
}
