import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

export default function MenuItem({ icon, label, close, onClick = () => {}, className }) {
  const { t } = useTranslation();

  return (
    <div
      onClick={(event) => [onClick(event), close()]}
      className={classNames(
        'rounded hover:bg-sky-500 hover:text-white flex cursor-pointer items-center py-2 pr-8',
        className
      )}
    >
      <FAIcon
        collection="fas"
        icon={icon}
        size="small"
        className={classNames('mx-1')}
      />
      <span className="ml-1 text-sm ">{t(label)}</span>
    </div>
  );
}
