import Scope from 'entities/Scope/Scope';
import Location from 'entities/Location/Location';
import Job from 'entities/Job/Job';
import { contractStates } from 'lists/contractStates';
import Manager from 'entities/Manager/Manager';
import Maintenance from 'entities/Maintenance/Maintenance';
import Equipment from 'entities/Equipment/Equipment';
import Client from 'entities/Client/Client';
import Intervener from 'entities/Intervener/Intervener';
import ReportFilter from './Filters/ReportFilter';
import AccountingFilter from './Filters/AccountingFilter';
import { bCategories, bDomaines } from 'lists/blachere';

export const ticketStateFilter = {
  label: 'state',
  key: 'states',
  type: 'List',
  params: {
    datas: Object.keys(contractStates).map((s) => ({
      label: contractStates[s].label,
      value: s,
      color: contractStates[s].color
    }))
  }
};

const TicketFilters = (config, { tab, scheduler, calendar, customFields, userMaintenance, entity }, auth) => {
  return [
    {
      type: 'Section',
      label: 'inProgress',
      color: '#31b55a',
      key: 'open',
      default: true,
      show: true,
      hidden: scheduler || entity === 'reportFiles'
    },
    {
      type: 'Section',
      label: 'closed',
      color: '#2c3e50',
      key: 'closed',
      default: entity === 'reportFiles',
      show: true,
      hidden: scheduler || entity === 'reportFiles'
    },
    {
      type: 'Section',
      label: 'maintenancesTitle',
      color: '#e63d53',
      key: 'preventifs',
      default: entity === 'reportFiles' || calendar || scheduler || userMaintenance,
      show: config.feature.preventif,
      disabled: entity === 'reportFiles',
      hidden: scheduler
    },
    {
      type: 'Section',
      label: 'withoutIntervenerTitle',
      key: 'withoutIntervener',
      show: true,
      default: false,
      hidden: scheduler
    },
    {
      type: 'Section',
      label: 'withoutJobTitle',
      key: 'withoutJob',
      show: true,
      default: false,
      hidden: scheduler
    },
    {
      type: 'Section',
      label: 'afterDeadline',
      key: 'afterDeadline',
      color: '#e63d53',
      show: config?.follow_up_intervention_delay || config?.follow_up_resolution_delay,
      default: false,
      hidden: scheduler
    },
    {
      label: 'clientsTitle',
      key: 'clients',
      type: 'Entity',
      params: {
        entity: Client,
        entityProps: {}
      },
      disabled: false,
      show: config.isContractor,
      default: []
    },
    {
      label: 'intervenersTitle',
      key: 'interveners',
      type: 'Entity',
      params: {
        entity: Intervener
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      ...ticketStateFilter,
      show: true,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'quotes',
      key: 'devis',
      type: 'Custom',
      params: {
        type: 'devis',
        component: (filter) => <AccountingFilter filter={filter} />
      },
      show: true,
      disabled: false,
      default: {
        isDirty: false,
        children: false,
        documents: 0,
        no_validated: false,
        price: {
          op: 'eq',
          value1: false,
          value2: false
        },
        reference: '',
        validated: false,
        _users: [],
        equipments: []
      },
      hidden: entity === 'reportFiles'
    },
    {
      label: 'invoices',
      key: 'facture',
      type: 'Custom',
      params: {
        type: 'factures',
        component: (filter) => <AccountingFilter filter={filter} />
      },
      show: true,
      disabled: false,
      default: {
        isDirty: false,
        children: false,
        documents: 0,
        reference: '',
        price: {
          op: 'eq',
          value1: false,
          value2: false
        }
      },
      hidden: entity === 'reportFiles'
    },
    {
      label: 'types',
      key: 'typologies',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: {
          type: 'ticketTypology',
          hiddenFilters: {
            only: [],
            parents: []
          }
        }
      },
      show: !userMaintenance,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'locations',
      key: 'locations',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: true,
              facturation: false
            }
          }
        }
      },
      show: !config.monolocation,
      hidden: !!auth.interface.isUser && !!auth.interface?._locations?.length,
      disabled: false,
      default: []
    },
    {
      label: 'jobs',
      key: 'jobs',
      type: 'Entity',
      params: {
        entity: Job,
        entityProps: {
          type: 'jobs',
          hiddenFilters: {
            section: {
              mine: true,
              bobdesk: true,
              bobdepannage: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'managersTitle',
      key: 'managers',
      type: 'Entity',
      params: {
        entity: Manager,
        entityProps: {
          type: 'jobs',
          hiddenFilters: {
            section: {
              global: false,
              local: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      type: 'Section',
      label: 'immanagerSection',
      color: '#fc427b',
      key: 'immanager',
      default: false,
      show: config.isManager,
      hidden: false || entity === 'reportFiles'
    },
    {
      type: 'Section',
      label: 'deadlineSection',
      color: '#8e44ad',
      key: 'deadline',
      default: false,
      show: config.isManager,
      hidden: false || entity === 'reportFiles'
    },
    {
      type: 'Section',
      label: 'withoutAManager',
      color: '#ef7a1f',
      key: 'nomanager',
      default: false,
      show: config.isManager,
      hidden: false || entity === 'reportFiles'
    },
    {
      label: config.isBlachere ? 'blachCategories' : 'domains',
      key: 'categories',
      show: config.isBlachere || !!config?.job_domains?.length,
      type: 'List',
      params: {
        datas: config.isBlachere
          ? bCategories
          : !!config?.job_domains?.length
          ? config.job_domains.map((d) => ({ label: d.label, value: d._id })) || []
          : []
      },
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'domains',
      key: 'domains',
      show: !!config.isBlachere,
      type: 'List',
      params: {
        datas: bDomaines
      },
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'maintenancesTitle',
      key: 'preventifs',
      type: 'Entity',
      params: {
        entity: Maintenance,
        entityProps: {
          hiddenFilters: {
            section: {
              alert: true,
              late: true,
              good: true
            }
          }
        }
      },
      show: config.feature.preventif,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'validatedPeriod',
      key: 'preventif_checked',
      type: 'List',
      params: {
        datas: [
          { label: 'validatedF', value: 'validated' },
          { label: 'notValidatedF', value: 'notvalidated' }
        ]
      },
      show: config.feature.preventif,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'reportsTitle',
      key: 'report',
      type: 'Custom',
      params: {
        component: (filter) => <ReportFilter filter={filter} />
      },
      show: true,
      disabled: false,
      default: {
        ir: 0,
        reserve: 0
      },
      hidden: entity === 'reportFiles'
    },
    {
      label: 'priorities',
      key: 'urgencies',
      type: 'List',
      params: {
        datas: config?.urgencies
          ? Object.keys(config.urgencies)
              .map((key) => ({ label: config.urgencies[key].label, value: key }))
              .filter((u) => u?.label?.length > 0)
          : []
      },
      show: true,
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    },
    {
      label: 'equipmentsTitle',
      key: 'equipments',
      type: 'Entity',
      params: {
        entity: Equipment,
        entityProps: {}
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'date',
      key: 'dates',
      type: 'Date',
      params: {
        datas: [
          {
            label: 'createdDate',
            value: 'created_at'
          },
          {
            label: 'programmedVisitDate',
            value: 'visit_date'
          },
          {
            label: 'finishingDate',
            value: 'flag_finished'
          },
          {
            label: 'interventionDate',
            value: 'intervention_date'
          }
        ]
      },
      show: true,
      disabled: false,
      default: {
        isDirty: false,
        created_at: {
          comparisonOperator: 'gte',
          value: null
        },
        flag_finished: {
          comparisonOperator: 'gte',
          value: null
        },
        intervention_date: {
          comparisonOperator: 'gte',
          value: null
        },
        visit_date: {
          comparisonOperator: 'gte',
          value: null
        }
      },
      hidden: entity === 'reportFiles'
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: [],
      hidden: entity === 'reportFiles'
    }
  ].filter((e) => e.show);
};

export const formatFilter = (filters, auth) => {
  const only_show_user_tickets = auth?.interface?._role?.permissions?.tickets?.includes('show_only_user_tickets');

  const _filters = filters;

  if (_filters['preventifs']) {
    _filters['preventifs'] = _filters['preventifs'].map((p) => p._id);
  }

  if (!filters.locations?.length && auth.interface._locations) {
    _filters['locations'] = auth.interface._locations;
  }

  if (!filters.jobs?.length && auth.interface._jobs) {
    _filters['jobs'] = auth.interface._jobs;
  }

  if (!filters.clients?.length && auth.interface._scope_clients) {
    _filters['clients'] = auth.interface._scope_clients;
  }

  if (_filters['dates']) {
    _filters['dates'] = Object.keys(_filters['dates']).reduce((acc, curr) => {
      const next = cleanDate(_filters['dates'][curr]);
      return {
        ...acc,
        ...(next ? { [curr]: next } : {})
      };
    }, {});
  }

  if (only_show_user_tickets) {
    _filters['section'] = {
      ..._filters['section'],
      immanager: true
    };
  }
  return _filters;
};

export default TicketFilters;

//@TODO: This logic should be in List.js
const cleanDate = (filter) => {
  const { comparisonOperator: operator, value } = filter;

  if (value === null) {
    return null;
  }

  switch (operator) {
    case 'exists':
      return { operator, value: true };
    case 'not_exists':
      return { operator, value: true };
    default:
      return filter;
  }
};
