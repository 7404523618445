import { useState, createElement } from 'react';
import {
  Box,
  Button,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid
} from '@material-ui/core';
import { useField } from 'frmx';
import useStyles from './Filter.styles';
import useKeys from '@flowsn4ke/usekeys';

import { Add, EditOutlined } from '@material-ui/icons';

import { useEntity } from 'contexts/entities/entityContext';

import { fieldTypes } from 'fieldSections/fieldTypes';
import { isDate } from 'date-fns';
import { dateToLocalFormat } from 'utils/dates';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function FilterField({ filter }) {
  const { customFields } = useEntity();
  const classes = useStyles();
  const k = useKeys();
  const { value: field = [], setValue: setField } = useField(filter.key);

  const { t } = useTranslation();

  const [newValue, setNewValue] = useState(null);
  const [editField, setEditField] = useState(null);
  const [newComparisonOperator, setNewComparisonOperator] = useState(null);

  const isUpdate = !!editField && field?.find((f) => f._field === editField);

  const options = customFields.reduce((acc, curr) => {
    return [
      ...acc,
      ...curr.fields.map((field) => ({
        label: curr.label + ' - ' + field.label,
        value: field._id,
        valueType: field.valueType,
        extra: field.extra
      }))
    ];
  }, []);

  const onDeleteFieldFilter = (deleteFieldFilter) => {
    setField(field?.filter((el) => el._field !== deleteFieldFilter._field));
  };

  const onSelectFieldFilter = ({ _field, value, comparisonOperator }) => {
    setEditField(_field);
    setNewValue(value);
    setNewComparisonOperator(comparisonOperator);
  };

  const onSetFieldFilter = () => {
    const isExist = !!field?.find((el) => el._field === editField);

    setField(
      isExist
        ? field.map((f) =>
            f._field !== editField
              ? f
              : { ...f, value: newValue, comparisonOperator: newComparisonOperator }
          )
        : [
            ...field,
            {
              _field: editField,
              value: newValue,
              comparisonOperator: newComparisonOperator
            }
          ]
    );
  };

  const onChangeSelectedFieldComparisonOperator = (ev) => setNewComparisonOperator(ev.target.value);

  const selectFieldDefinition = editField && options.find(({ value }) => value === editField);

  return (
    <Box
      padding={'16px'}
      className={classes.filter}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          {!!field?.length && (
            <Box
              display="flex"
              flexWrap="wrap"
            >
              <ChipCustomField
                field={field}
                options={options}
                onDeleteFieldFilter={onDeleteFieldFilter}
                onSelectFieldFilter={onSelectFieldFilter}
              />
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="select-field-label">Champ sélectionné</InputLabel>
            <Select
              readOnly={isUpdate}
              fullWidth
              id="select-field-label"
              labelId="select-field-label"
              variant="outlined"
              label={t('selectedField')}
              onChange={(ev) => setEditField(ev.target.value)}
              value={editField || ''}
            >
              {options.map((o, i) => (
                <MenuItem
                  key={k(i)}
                  value={o.value}
                >
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {!!editField && (
          <>
            <Grid
              item
              xs={5}
            >
              <FormControl
                fullWidth
                variant={'outlined'}
              >
                <InputLabel id="comparison-operator-label">{t('comparisonOperator')}</InputLabel>
                <Select
                  fullWidth
                  labelId="comparison-operator-label"
                  id="comparison-operator-label"
                  label={t('comparisonOperator')}
                  variant={'outlined'}
                  value={newComparisonOperator ? newComparisonOperator : 'eq'}
                  onChange={onChangeSelectedFieldComparisonOperator}
                >
                  <MenuItem value={'eq'}>Égale à</MenuItem>
                  <MenuItem value={'ne'}>Différent de</MenuItem>
                  {['multipleSelect'].includes(selectFieldDefinition.valueType)
                    ? [
                        <MenuItem value={'in'}>Contient</MenuItem>,
                        <MenuItem value={'nin'}>Ne contient pas</MenuItem>
                      ]
                    : []}
                  {['number', 'date', 'datetime'].includes(selectFieldDefinition.valueType)
                    ? [
                        <MenuItem value={'gt'}>Supérieure à</MenuItem>,
                        <MenuItem value={'gte'}>Supérieure ou égale à</MenuItem>,
                        <MenuItem value={'lt'}>Inférieur à</MenuItem>,
                        <MenuItem value={'lte'}>Inférieur ou égale à</MenuItem>
                      ]
                    : []}
                  <MenuItem value={'exists'}>Est renseigné</MenuItem>
                  <MenuItem value={'not_exists'}>N'est pas renseigné</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!['exists', 'not_exists'].includes(newComparisonOperator) && (
              <Grid
                item
                xs={7}
              >
                <CustomInputField
                  selectFieldDefinition={selectFieldDefinition}
                  onChange={setNewValue}
                  newValue={newValue}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Button
                fullWidth
                variant="outlined"
                endIcon={isUpdate ? <EditOutlined /> : <Add />}
                onClick={() => [
                  onSetFieldFilter(),
                  setEditField(null),
                  setNewValue(null),
                  setNewComparisonOperator(null)
                ]}
              >
                {isUpdate ? t('edit') : t('add')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

function CustomInputField({ selectFieldDefinition, onChange, newValue }) {
  return fieldTypes[selectFieldDefinition.valueType].fieldComponent !== null
    ? createElement(fieldTypes[selectFieldDefinition.valueType].fieldComponent, {
        onChange,
        customField: selectFieldDefinition,
        valueControlled: newValue
      })
    : null;
}

function ChipCustomField({ field, options, onDeleteFieldFilter, onSelectFieldFilter }) {
  const k1 = useKeys();

  const { t } = useTranslation();

  const operatorString = {
    eq: '=',
    ne: '≠',
    in: t('contains'),
    ni: t('doesNotContains'),
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    exists: t('isFilled'),
    not_exists: t('isNotFilled')
  };

  const formatValue = (value) => {
    return isDate(value)
      ? dateToLocalFormat(value, 'P')
      : isArray(value)
        ? value.join(', ')
        : value;
  };

  return field.map((filter, i) => {
    const v = field.find((element) => element._field === filter._field);
    const option = options.find((element) => element.value === filter._field);

    if (!option) {
      return <></>;
    }
    return (
      <Chip
        key={k1(i)}
        label={
          <span>
            {option.label}{' '}
            {!operatorString[v.comparisonOperator] ? '=' : operatorString[v.comparisonOperator]}{' '}
            <strong>{formatValue(v.value)}</strong>
          </span>
        }
        onDelete={() => onDeleteFieldFilter(filter)}
        onClick={() => onSelectFieldFilter(filter)} // TODO verify this correct
        variant="outlined"
        style={{ margin: '3px 3px 3px 0' }}
      />
    );
  });
}
